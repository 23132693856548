import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './i18n';
import './logger';
import './index.scss';
import { AuthProvider } from './provider/AuthProvider';
import { routes } from './routes';
import { NotificationProvider } from './common/NotificationContext';
import '@cloudscape-design/global-styles/index.css';
import { MetricsProvider } from './provider/MetricsProvider';
import { ToolsProvider } from './common/tools/ToolsProvider';
import { AnnotationContext } from './common/tools/AnnotationContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5min
      refetchOnWindowFocus: false,
    },
  },
});

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.
const container = document.getElementById('root');
const root = createRoot(container!);

const prepareMsw = async () => {
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW) {
    const { worker } = await import('test/server/browser');
    await worker.start();
  }
  return Promise.resolve();
};

prepareMsw().then(() => {
  root.render(
    <React.StrictMode>
      <MetricsProvider>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <AuthProvider>
              <ToolsProvider>
                <AnnotationContext>
                  <RouterProvider router={createBrowserRouter(routes)} />
                </AnnotationContext>
              </ToolsProvider>
            </AuthProvider>
          </NotificationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MetricsProvider>
    </React.StrictMode>,
  );
});
